import * as React from 'react';
import { Link } from 'gatsby';
import './navigation.component.scss';
import Modal from '@material-ui/core/Modal';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';

function toggleNav(open: boolean, position: number) {
  const navEl = document.querySelector('nav');
  navEl.style.transform = `translateY(${open ? 0 : -150}px)`;
  if (position > 50 && open) {
    navEl.classList.add('snappy');
  } else {
    navEl.classList.remove('snappy');
  }
}

export function snappyNav() {
  if (typeof window !== 'undefined') {
    const state: {
      position: number;
      direction: 'up' | 'down' | 'init';
      open: boolean;
    } = {
      position: window.scrollY,
      direction: 'init',
      open: true,
    };
    window.addEventListener('scroll', e => {
      if (window.scrollY - state.position < 0) {
        state.open = true;
        state.direction = 'up';
        toggleNav(true, window.scrollY);
        state.position = window.scrollY;
      } else if (window.scrollY - state.position > 200) {
        state.open = false;
        state.direction = 'down';
        toggleNav(false, window.scrollY);
        state.position = window.scrollY;
      }
    });
  }
}

export function Navigation({
  config,
  logo,
  logoAlternative,
}: {
  config: NavigationConfig;
  logo: ContentfulImage;
  logoAlternative: ContentfulImage;
}) {
  snappyNav();

  const mobile = typeof window !== `undefined` && window.innerWidth < 800;
  const [open, setOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(-1);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : -1);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  if (mobile) {
    return (
      <nav className="navigation-container">
        <Link to="/">
          <img
            className="logo logo-regular"
            src={logo.file.url}
            alt="Norsepower logo"
          />
          <img
            className="logo logo-alternative"
            src={logoAlternative.file.url}
            alt="Norsepower logo"
          />
        </Link>

        <img
          className="menuButton"
          onClick={handleOpen}
          src={require('../../assets/icons/mobile-menu.svg')}
          alt="Open mobile navigation menu"
        />

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={handleClose}
        >
          <div className="nav-modal">
            <div className="wave-background" />
            <div className="header">
              <Link to="/">
                <img
                  className="logo logo-alternative"
                  src={logoAlternative.file.url}
                  alt="Norsepower logo"
                />
              </Link>
              <img
                className="close"
                onClick={handleClose}
                src={require('../../assets/icons/x.svg')}
                alt="Close navigation modal"
              />
            </div>
            <div className="pages">
              {config.links.map((link, i) => {
                if (!link.path) {
                  return (
                    <ExpansionPanel
                      key={i}
                      square={true}
                      elevation={0}
                      className="sub-items-container"
                      expanded={expanded === i}
                      onChange={handleChange(i)}
                    >
                      <ExpansionPanelSummary
                        aria-controls="panel1a-content"
                        className="sub-items-parent"
                      >
                        <span
                          className={
                            'title ' + (expanded === i ? 'open' : 'closed')
                          }
                        >
                          {link.title}
                        </span>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        {link.children.map((childLink, i) => (
                          <Link
                            className="sub-item"
                            key={i}
                            to={childLink.path + '/'}
                          >
                            {childLink.title}
                          </Link>
                        ))}
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  );
                }
                return (
                  <Link className="item" key={i} to={link.path + '/' || ''}>
                    {link.title}
                  </Link>
                );
              })}
            </div>
          </div>
        </Modal>
      </nav>
    );
  }

  return (
    <nav className="navigation-container">
      <Link to="/">
        <img
          className="logo logo-regular"
          src={logo.file.url}
          alt="Norsepower logo"
        />
        <img
          className="logo logo-alternative"
          src={logoAlternative.file.url}
          alt="Norsepower logo"
        />
      </Link>
      <div className="pages">
        {config.links.map((link, i) => {
          if (!link.path) {
            return (
              <div className="dropdown-container" key={i}>
                <a className="item dropdown-parent">{link.title}</a>
                <div className="dropdown-content-wrapper">
                  <div className="dropdown-content">
                    {link.children.map((childLink, i) => (
                      <Link className="item link" key={i} to={childLink.path}>
                        {childLink.title}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            );
          }
          return (
            <Link className="item link" key={i} to={link.path}>
              {link.title}
            </Link>
          );
        })}
      </div>
    </nav>
  );
}
