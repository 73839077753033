import React from 'react';
import lozad from 'lozad';
import './image.component.scss';

const imageSizes = [500, 1000, 1550, 2000, 2600];

const responsiveSources = (fields, scale) => [
  ...imageSizes.map((size, i) => (
    <source
      key={i}
      srcSet={
        'https:' +
        fields.file.url +
        `?fm=webp&q=${70}&w=${Math.ceil(
          size * 1.1 * (size > 800 ? scale || 1 : 2.2),
        )}`
      }
      media={`(max-width: ${size}px)`}
      type="image/webp"
    />
  )),
  ...imageSizes.map((size, i) => (
    <source
      key={100 + i}
      srcSet={
        'https:' +
        fields.file.url +
        `?fm=jpg&q=${70}&w=${size * (size > 800 ? scale || 1 : 2.2)}`
      }
      media={`(max-width: ${size}px)`}
    />
  )),
];

export interface ImageProps {
  fields: ContentfulImage;
  alt?: string;
  scale?: number;
  className?: string;
}

export interface ImageState {
  width?: number;
  height?: number;
}

export class Image extends React.Component<ImageProps, ImageState> {
  public observer: { observe: () => void; observer: IntersectionObserver };
  public element: HTMLPictureElement;
  constructor(props: ImageProps) {
    super(props);
    this.state = {};
  }
  setElement(ref: HTMLElement) {
    this.element = ref;
    this.observer = lozad(this.element, {
      rootMargin: '242px 0px',
      loaded: el => {
        // Custom implementation on a loaded element
        el.classList.add('loaded');
        el.classList.add('fadein');
      },
    });
    this.observer.observe();
  }
  componentDidMount() {
    this.setState({
      width: this.element.clientWidth,
      height: this.element.clientHeight,
    });
  }
  componentWillUnmount() {
    this.observer.observer.disconnect();
  }
  render() {
    const { fields, alt, scale, className } = this.props;
    return (
      <picture
        ref={ref => this.setElement(ref)}
        className={`picture lazy ${className}`}
        data-iesrc={`https:${fields.file.url}?q=60&w=2000`}
        /* data-background-image={`https:${fields.file.url}?fm=jpg&fl=progressive&q=25&w=800`} */
        data-alt={alt}
      >
        {this.state.width && this.state.height
          ? [
              <source
                key={1}
                srcSet={
                  'https:' +
                  fields.file.url +
                  `?fm=webp&q=60&fit=fill&w=${Math.ceil(this.state.width / 25) *
                    25}&h=${Math.ceil(this.state.height / 25) * 25}`
                }
                type="image/webp"
              />,
              <source
                key={2}
                srcSet={
                  'https:' +
                  fields.file.url +
                  `?fm=jpg&fl=progressive&q=80&w=${Math.ceil(
                    this.state.width / 25,
                  ) * 25}&h=${Math.ceil(this.state.height / 25) * 25}`
                }
              />,
            ]
          : responsiveSources(fields, scale)}
      </picture>
    );
  }
}

/* 


*/
