import * as React from 'react';
import { Link } from 'gatsby';
import './footer.component.scss';

export function Footer({
  config,
  logo,
}: {
  config: FooterConfig;
  logo: ContentfulImage;
}) {
  return (
    <footer>
      <div className="wave-background" />
      <div className="left">
        {config.left.map((link, i) => (
          <Link className="item" key={i} to={link.path || link.href}>
            {link.title}
          </Link>
        ))}
      </div>
      <div className="info">
        <img className="logo" src={logo.file.url} alt="Norsepower logo" />
        <a className="email" href={'mailto:' + config.info.email}>
          {config.info.email}
        </a>
        <p className="address">{config.info.address}</p>
      </div>
      <div className="right">
        {config.right.map((link, i) => {
          if (!link.title.trim()) {
            return <div key={i} className="item placeholder"></div>;
          }
          if (link.href) {
            return (
              <a
                key={i}
                href={link.href}
                className="item href"
                target="_blank"
                rel="noopener"
              >
                {link.title}
              </a>
            );
          }
          return (
            <Link className="item" key={i} to={link.path || link.href}>
              {link.title}
            </Link>
          );
        })}
      </div>
    </footer>
  );
}
